.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.homepage-container {
  background: url("../src/01.jpg") no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Adjust text color for better contrast */
}

.content {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Adds a dark overlay to enhance text visibility */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
}

.circle {
  background-color: lightcoral;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  max-width: 100px; /* Adjust size as needed */
}

.circle-text {
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.btn-outline-primary {
  color: white !important;
  border-color: white !important;
}

.btn-outline-primary:hover {
  background-color: white !important;
  color: black !important;
}

.main-page {
  font-family: "Arial, sans-serif";
  background-color: #f8f9fa;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
